<template>
  <div style="height: 100vh; padding:10px">
      <div class="header">
        <van-row gutter="10">
          <van-col @click="onClickBack">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
              style="height: 25px"
            />
            <span style="font-size: 25px; color: white;font-weight:bold">事件详情</span>
          </van-col>
        </van-row>
      </div>
      <div style="height:90vh;overflow-y:scroll;margin-top:.2rem">

      <card v-if="!edit" style="margin:.5rem 0 4rem 0">
        <template #content>
          <tour-info-top :tour="event.travel_list" :titleClickable="false"></tour-info-top>
          <van-divider dashed/>
          <van-row class="row">
            <van-col span="12">
              <span style="font-weight:600">紧急事件</span>
            </van-col>
            <van-col span="12" style="text-align:right">
              <span style="color:grey">{{event.created_at}}</span>
            </van-col>
          </van-row>
          <van-row class="row" style="margin-top:10px">
            <span style="color:grey">{{event.report_content}}</span>
          </van-row>
          <van-row class="row" style="margin-top:10px">
            <van-image :src="event.attachment_url" fit="cover" height="200" width="200"
              @click="onImageClick"/>
          </van-row>
          <van-divider dashed/>
          <van-row class="row" style="margin-top:10px">
            <van-col span="18">
              <span style="font-weight:600">审核状态</span>
            </van-col>
            <van-col span="6" style="text-align:right">
              <van-tag color="#13C2C225" text-color="#13C2C2" round
                v-if="event.is_approved && event.approve_status == 1">{{event.approve_status_text}}</van-tag>
              <van-tag color="#4168EE12" text-color="#4168EE" round
                v-if="event.is_approved && event.approve_status == 2">{{event.approve_status_text}}</van-tag>
              <van-tag color="#6f6f6f23" text-color="#606060" round
                v-if="!event.is_approved">待审批</van-tag>
            </van-col>
          </van-row>
          <van-divider dashed/>
          <van-row class="row" style="margin-top:10px">
            <van-col span="20">
              <span style="font-weight:600">审核意见</span>
            </van-col>
            <van-col span="4" style="text-align:right" v-if="event.replies.length == 0">
              <span style="font-size:14px;color:#666666">空</span>
            </van-col>
          </van-row>
          <van-row class="row" style="margin-top:10px">
            <van-cell :title="reply.user_name" :value="reply.reply_at"
              :label="reply.content" style="background-color:#f6f6f6"
              v-for="(reply, idx) in event.replies" :key="idx"/>
          </van-row>
        </template>
      </card>
      <card v-if="edit" style="margin-top: 30px">
        <template #content>
          <fieldForm
            :submit="save"
            :modal="emergency"
            :fieldData="fields"
            v-on:formUpdate="onFormUpdate"
          ></fieldForm>
        </template>
      </card>
      </div>
    <div class="action_bg" v-if="!event.is_approved">
      <van-button
        block
        type="info"
        v-if="!edit"
        native-type="primary"
        class="action_btn"
        color="#395EE8"
        @click="edit = true"
        >编辑</van-button
      >
      <van-button
        block
        type="info"
        v-if="edit"
        native-type="submit"
        style="height: 50px"
        class="action_btn"
        color="#395EE8"
        @click="save()"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import api from "@/services/apis.js";
import { Toast, ImagePreview } from "vant";
import card from "@/views/components/Card";
import { mapGetters } from "vuex";
import tourInfoTop from "@/views/components/TourInfoTop"
import fieldForm from "@/views/components/FieldForm"
export default {
  components: {
    card, tourInfoTop, fieldForm
  },
  data() {
    return {
      edit: false,
      loading: false,
      emergency: {
        report_content: "",
        attachment_url: "",
        travel_list_id: 0,
        id: 0
      },
      fields: [
        {
          title: "事件详情",
          name: "report_content",
          type: "textarea",
          rule: [{ required: true, message: "" }],
          inline: false,
          show_word_limit: true,
          rows: 3,
          max_length: 100
        },
        {
          title: "附件",
          name: "attachment_url",
          type: "file",
          rule: [],
          max_count: 1,
          inline: false,
        },
        {
          title: "通报时间",
          name: "fmt_date",
          value: "",
          type: "info",
          disabled: true
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(
      "events/getCurrentEvent",
      parseInt(this.$route.params.id)
    )
    this.emergency.id = this.$route.params.id
  },
  watch: {
    event: {
      handler(data) {
        this.emergency.report_content = data.report_content
        this.emergency.attachment_url = data.attachment_url
      }
    }
  },
  computed: {
    ...mapGetters("events", {
      event: "getCurrentEvent",
    }),
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    onFormUpdate(data) {
      this.emergency.report_content = data.report_content
      this.emergency.attachment_url = data.attachment_url
    },
    onImageClick() {
      ImagePreview({images: [this.emergency.attachment_url], closeable: true})
    },
    save() {
      const _self = this;
      const data = {
        report_content: _self.emergency.report_content,
        travel_list_id: _self.event.travel_list.id,
        attachment_url: _self.emergency.attachment_url,
        id: _self.emergency.id,
      };
      api.update_emergency_reports(data).then(
        (res) => {
          if (res.data.status === 200) {
            Toast("更新成功");
            _self.edit = false;
            this.$store.dispatch( "events/getCurrentEvent", parseInt(this.$route.params.id))
          }
        },
        (err) => {
          Toast("更新失败");
          console.log(err);
        }
      );
    },
    editEvent() {
      this.edit = true;
    },
    onConfirm() {},
    refreshInfo() {
      const _self = this
      this.$store.dispatch( "events/getCurrentEvent",
        parseInt(this.$route.params.id)).then((res) => {
          if(res.status === 200) {
            _self.loading = false
          }
      })
    
    }
  },
};
</script>

<style>
.action_btn {
  position: fixed;
  bottom: 10px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
}
.row {
  font-size: 14px;
}
</style>